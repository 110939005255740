import AOS from 'aos';

function menuA1() {
    const selector = {
        heading: '.js-accordion_heading',
        headingTitle: '.js-heading-title',
        group: '.js-accordion_group',
        dropdown: '.js-accordion_dropdown',
        track: '.js-accordion_track',
        currentItem: '.current-menu-item span',
    };

    // Active state classes.
    const state = {
        groupActive: 'is-active',
        trackActive: 'rotate-180',
    };

    // Replace accordion title if a current page exists in the menu
    const currentItem = document.querySelector(selector.currentItem);

    if (currentItem) {
        document.querySelector(selector.headingTitle).innerHTML =
            currentItem.innerHTML;
    }

    const toggleAccordion = (e) => {
        console.log('clicked');
        const group = e.target.closest(selector.group);
        const dropdown = group.querySelector(selector.dropdown);
        const track = group.querySelector(selector.track);

        group.classList.toggle(state.groupActive);
        track.classList.toggle(state.trackActive);
        $(dropdown).slideToggle(300); // jQuery is used here, hence the eslint-disable-next-line comment.

        // refresh Animation on Scroll module Events
        setTimeout(function () {
            AOS.refresh();
        }, 300);
    };

    document.querySelectorAll(selector.heading).forEach((heading) => {
        heading.addEventListener('click', toggleAccordion);
    });

    // Dropdown menu functionality
    const activeClass = 'is-active';

    // Define selectors for desktop and mobile
    const desktopMenuSelector = '.o-menu-a-1__nav--desktop';
    const mobileMenuSelector = '.o-menu-a-1__nav--mobile';
    const linkItemHasChildren = '.menu-item-has-children';
    const subMenuSelector = '.m-nav__sub-menu';

    // Toggle the dropdown animation and add class to the parent item.
    function toggleDropdown(e) {
        e.preventDefault();

        const submenu = this.nextElementSibling; // Get the submenu
        const parentItem = this.closest('.m-nav__item'); // Get the parent item

        if (submenu && submenu.classList.contains('m-nav__sub-menu')) {
            const isVisible = submenu.classList.contains('is-active'); // Check if submenu is open

            // Toggle active class on the link and parent item
            this.classList.toggle(activeClass, !isVisible);
            this.querySelector('.js-plus-toggle')?.classList.toggle(
                'm-nav__plus-toggle--opened',
                !isVisible,
            );

            if (parentItem) {
                parentItem.classList.toggle(activeClass, !isVisible);
            }

            // If the submenu is not already active, we need to animate it open
            if (!isVisible) {
                submenu.style.maxHeight = `${submenu.scrollHeight}px`; // Set max-height for animation
                submenu.offsetHeight; // Trigger a reflow to apply max-height immediately
                submenu.classList.add('is-active'); // Add active class
            } else {
                // Otherwise, close the submenu
                submenu.classList.remove('is-active');
                submenu.style.maxHeight = '0px'; // Reset max-height to close the submenu
            }
        }
    }

    // Close all open submenus when clicking outside the menu.
    function closeAllSubmenus(menuSelector) {
        const openSubmenus = document.querySelectorAll(
            `${menuSelector} ${subMenuSelector}.is-active`,
        );
        openSubmenus.forEach((submenu) => {
            submenu.classList.remove('is-active');
            submenu.style.maxHeight = '0px'; // Reset max-height when closing
        });

        // Remove active class from all menu items
        const activeLinks = document.querySelectorAll(
            `${menuSelector} ${linkItemHasChildren}.is-active`,
        );
        activeLinks.forEach((link) => link.classList.remove('is-active'));

        // Remove active class from all parent items
        const activeParentItems = document.querySelectorAll(
            `${menuSelector} .m-nav__item.is-active`,
        );
        activeParentItems.forEach((item) => item.classList.remove('is-active'));
    }

    // Function to handle menu interaction for both desktop and mobile
    function setupMenu(menuSelector) {
        // Attach event listener to menu items with children
        const menuItems = document.querySelectorAll(
            `${menuSelector} ${linkItemHasChildren}`,
        );
        menuItems.forEach((item) => {
            item.addEventListener('click', function (e) {
                // Toggle submenu visibility when clicking on the menu item
                toggleDropdown.call(this, e);
                // Prevent the event from propagating to the document (prevent closing the menu immediately)
                e.stopPropagation();
            });
        });

        // Close all submenus when clicking outside the menu
        document.addEventListener('click', function (e) {
            if (!e.target.closest(menuSelector)) {
                closeAllSubmenus(menuSelector);
            }
        });

        // Prevent the menu from closing if the click was inside the menu
        const menuContainer = document.querySelector(menuSelector);
        if (menuContainer) {
            menuContainer.addEventListener('click', function (e) {
                e.stopPropagation(); // Prevent closing submenus when clicking inside the menu
            });
        }
    }

    // Initialize menus for both desktop and mobile
    setupMenu(desktopMenuSelector);
    setupMenu(mobileMenuSelector);
}

export default menuA1;
